import axios from 'axios';

import { network } from '../../services';

const allPolicies = async (filterBy: string, sortBy: string, page: number, limit: number, bearerToken: string, search: string) => {
    const response = await axios.get(`${network.baseApi}/policy?filter=${filterBy}&sortBy=${sortBy}&page=${page}&limit=${limit}&search=${search}`, { headers: { Authorization: bearerToken } })
    return response;
}

const getAPolicy = async (imei: string, bearerToken: string) => {
    const response = await axios.get(`${network.baseApi}/policy/${imei}`, { headers: { Authorization: bearerToken } })
    return response;
}

const getClaimsForPolicy = async (page: number, imei: string, bearerToken: string) => {
    const response = await axios.get(`${network.baseApi}/policy/${imei}/claim?page=${page}`, { headers: { Authorization: bearerToken } })
    return response;
}

const updateAPolicy = async (deviceId: number, deviceImei: string, size: string, make: string, model: string, color: string, price: number, startDate: string,
    customerId: number, customerTrn: string, phone: string, email: string, customerIdType: string, customerNationalId: string, premiumAmount: number, bearerToken: string) => {
    const response = await axios.patch(
        `${network.baseApi}/policy`,
        {
            deviceId,
            deviceImei,
            size,
            make,
            model,
            color,
            price,
            startDate,
            customerId,
            customerTrn,
            phone,
            email,
            customerIdType,
            customerNationalId,
            premiumAmount
        },
        { headers: { Authorization: bearerToken } }
    )
    return response;
}

const updateClaimStatus =async (imei: string, claimId: number, bearerToken: string, status: string, roles: number[]) => {
    const response = await axios.patch(
        network.baseApi + `/policy/${imei}/claim/${claimId}`,
        {status: status.toLowerCase(), roles},
        {
            headers: { Authorization: bearerToken },
        },
    )
    return response;
}

const PolicyService = {
    allPolicies,
    getAPolicy,
    getClaimsForPolicy,
    updateAPolicy,
    updateClaimStatus
}

export default PolicyService;
