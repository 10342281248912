import './claim-details.css';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import toast from 'react-hot-toast';

import QuickActionSidebar from '../Sidebar/QuickActionSidebar';
import Button from '../../../../Button/Button';
import TheftClaimStepOne from '../Theft/StepOne/ClaimType';
import TheftDetails from '../Theft/StepTwo/TheftDetails';
import TheftStatement from '../Theft/StepThree/Statement';
import PhysicalDamageClaimType from '../PhysicalDamage/StepOne/PhysicalDamageClaimType';
import DeviceIssues from '../PhysicalDamage/StepTwo/DeviceIssues';
import DeviceCondition from '../PhysicalDamage/StepThree/DeviceCondition';
import SupportingDocuments from '../PhysicalDamage/StepFour/SupportingDocuments';

import ClaimService, { DocumentData } from '../../../../../services/claims/claimService';
import AppToaster, { ToastType } from '../../../../AppToaster/AppToaster';
import { ToasterId } from '../../../../../enums/ToasterIds';
import { capitalizeFirstLetter, convertToBase64 } from '../../../../../utils/stringUtils';
import { deviceConditions, deviceIssues } from '../../../../../utils/claimUtil';
import { useAuthState } from '../../../../../contexts';
import PolicyContext from '../../../../../contexts/Policy/PolicyContext';


interface ClaimTypeDetail {
    id: string;
    policyNumber: string;
    deviceImei: string;
    closeModal: any;
    onStepOne?: boolean;
    setOnStepOne?: any;
    onStepTwo?: boolean;
    setOnStepTwo?: any;
    onStepThree?: boolean;
    onStepFour?: boolean;
    setOnStepThree?: any;
    setOnStepFour?: any;
    physicalDamage?: boolean;
    setPhysicalDamage?: any;
    liquidDamage?: boolean;
    setLiquidDamage?: any;
    theft?: boolean;
    setTheft?: any;
    setShowClaimTypeMenu?: any;
    stepOneComplete?: boolean;
    setStepOneComplete?: any;
    stepTwoComplete?: boolean;
    setStepTwoComplete?: any;
    stepThreeComplete?: boolean;
    setStepThreeComplete?: any;
    stepFourComplete?: boolean;
    setStepFourComplete?: any;
}

const ClaimTypeDetails = ({
    id,
    policyNumber,
    deviceImei,
    closeModal,
    setShowClaimTypeMenu,
    onStepOne,
    setOnStepOne,
    onStepTwo,
    setOnStepTwo,
    onStepThree,
    setOnStepThree,
    onStepFour,
    setOnStepFour,
    theft,
    setTheft,
    physicalDamage,
    setPhysicalDamage,
    liquidDamage,
    setLiquidDamage,
    stepOneComplete,
    setStepOneComplete,
    stepTwoComplete,
    setStepTwoComplete,
    stepThreeComplete,
    setStepThreeComplete,
    stepFourComplete,
    setStepFourComplete
}: ClaimTypeDetail) => {

    const [claimType, setClaimType] = useState('');
    const claimRelatedUserRoles = [1, 2, 3]
    useEffect(() => {
        if (theft) setClaimType('Theft');
        if (physicalDamage) setClaimType('Physical Damage');
        if (liquidDamage) setClaimType('Liquid Damage')
    }, [liquidDamage, physicalDamage, theft])

    const { token } = useAuthState();
    const location = useLocation();
    const { getClaimsForPolicy } = useContext(PolicyContext);
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

    /* ---------- Physical Damage variables ---------- */
    const [devicePassword, setDevicePassword] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [checkedDeviceIssues, setCheckedDeviceIssues] = useState<string[]>([]);

    const [selectedDeviceConditions, setSelectedDeviceConditions] = useState<string[]>([]);
    const [filesUploaded, setFilesUploaded] = useState<File[]>([]);

    /* ---------- Theft Claim---------- */
    const [policyNum, setPolicyNum] = useState(policyNumber);
    const [dateLost, setDateLost] = useState(new Date());
    const [dateReported, setDateReported] = useState(new Date());
    const [policeStation, setPoliceStation] = useState('');
    const [recordingOfficer, setRecordingOfficer] = useState('');
    const [statement, setStatement] = useState('');
    const [adviceServiceProvider, setAdviceServiceProvider] = useState(false);
    const checkTheftDetails = dateReported && dateLost && policeStation && recordingOfficer !== '' && adviceServiceProvider !== null;

    function isNumberInList(number: number, numberList: any) {
        return numberList.includes(number)
    }

    function convertStringListToNumberList(stringList: string[]) {
        return stringList.map(Number);
    }

    const createTheftClaim = (documents: DocumentData[]) => {
        let numberShutDown = adviceServiceProvider;
        ClaimService.createTheftClaim(id, bearerToken, deviceImei, documents, dateLost, policeStation, recordingOfficer, dateReported, numberShutDown, statement, claimRelatedUserRoles)
            .then(data => {
                console.log(data)
                showSuccessToast(claimType)
            })
            .catch(err => {
                console.log(err);
                showErrorToast(claimType)
            });
    }

    const createLiquidDamageClaim = (documents: DocumentData[]) => {
        ClaimService.createLiquidClaim(id, bearerToken, deviceImei, documents, devicePassword, statement, claimRelatedUserRoles)
            .then(data => {
                showSuccessToast(claimType)
                console.log(data)
            }).catch(err => {
                console.log(err)
                showErrorToast(claimType)
            })
    }

    function createPhysicalDamageClaim(documents: DocumentData[]) {
        ClaimService.createPhysicalClaim(
            id,
            bearerToken,
            deviceImei,
            documents,
            devicePassword,
            {
                deviceHasPower: isNumberInList(deviceConditions.find(({ id }) => id === 1)['id'], selectedDeviceConditions.map(Number)),
                deviceCharging: isNumberInList(deviceConditions.find(({ id }) => id === 2)['id'], selectedDeviceConditions.map(Number)),
                deviceRebootingCondition: isNumberInList(deviceConditions.find(({ id }) => id === 3)['id'], selectedDeviceConditions.map(Number)),
                volumeButtonsWork: isNumberInList(deviceConditions.find(({ id }) => id === 4)['id'], selectedDeviceConditions.map(Number)),
                cameraWorking: isNumberInList(deviceConditions.find(({ id }) => id === 5)['id'], selectedDeviceConditions.map(Number)),
                enterButtonWorking: isNumberInList(deviceConditions.find(({ id }) => id === 6)['id'], selectedDeviceConditions.map(Number)),
                optionButtonWorking: isNumberInList(deviceConditions.find(({ id }) => id === 7)['id'], selectedDeviceConditions.map(Number)),
                speakersWorking: isNumberInList(deviceConditions.find(({ id }) => id === 8)['id'], selectedDeviceConditions.map(Number)),
                touchScreenWorking: isNumberInList(deviceConditions.find(({ id }) => id === 9)['id'], selectedDeviceConditions.map(Number)),
                lensScratched: isNumberInList(deviceConditions.find(({ id }) => id === 10)['id'], selectedDeviceConditions.map(Number)),
                topOfDeviceScratched: isNumberInList(deviceConditions.find(({ id }) => id === 11)['id'], selectedDeviceConditions.map(Number)),
                deviceBootsUp: isNumberInList(deviceConditions.find(({ id }) => id === 12)['id'], selectedDeviceConditions.map(Number)),
                scrollButtonWorking: isNumberInList(deviceConditions.find(({ id }) => id === 13)['id'], selectedDeviceConditions.map(Number)),
                returnButtonWorking: isNumberInList(deviceConditions.find(({ id }) => id === 14)['id'], selectedDeviceConditions.map(Number)),
                keyboardButtonWorking: isNumberInList(deviceConditions.find(({ id }) => id === 15)['id'], selectedDeviceConditions.map(Number)),
                ableToPlayMedia: isNumberInList(deviceConditions.find(({ id }) => id === 16)['id'], selectedDeviceConditions.map(Number)),
                lcdOperational: isNumberInList(deviceConditions.find(({ id }) => id === 17)['id'], selectedDeviceConditions.map(Number)),
                sideRailsScratched: isNumberInList(deviceConditions.find(({ id }) => id === 18)['id'], selectedDeviceConditions.map(Number)),
                backOfDeviceScratched: isNumberInList(deviceConditions.find(({ id }) => id === 19)['id'], selectedDeviceConditions.map(Number)),
                lowerEndOfDeviceScratched: isNumberInList(deviceConditions.find(({ id }) => id === 20)['id'], selectedDeviceConditions.map(Number)),
            },
            {
                deviceNotCharging: isNumberInList(deviceIssues.find(({ id }) => id === 1)['id'], checkedDeviceIssues.map(Number)),
                deviceRebootingIssue: isNumberInList(deviceIssues.find(({ id }) => id === 2)['id'], checkedDeviceIssues.map(Number)),
                screenBlank: isNumberInList(deviceIssues.find(({ id }) => id === 3)['id'], checkedDeviceIssues.map(Number)),
                deviceNotComingOn: isNumberInList(deviceIssues.find(({ id }) => id === 4)['id'], checkedDeviceIssues.map(Number)),
                screenWhite: isNumberInList(deviceIssues.find(({ id }) => id === 5)['id'], checkedDeviceIssues.map(Number)),
                screenBroken: isNumberInList(deviceIssues.find(({ id }) => id === 6)['id'], checkedDeviceIssues.map(Number)),
                notHearingPerson: isNumberInList(deviceIssues.find(({ id }) => id === 7)['id'], checkedDeviceIssues.map(Number)),
                peopleCantHearWhenISpeak: isNumberInList(deviceIssues.find(({ id }) => id === 8)['id'], checkedDeviceIssues.map(Number)),
                notAbleToHearPhoneRing: isNumberInList(deviceIssues.find(({ id }) => id === 9)['id'], checkedDeviceIssues.map(Number)),
                notAbleToPlayMultimedia: isNumberInList(deviceIssues.find(({ id }) => id === 10)['id'], checkedDeviceIssues.map(Number)),
                unableToHearFromHeadphoneJack: isNumberInList(deviceIssues.find(({ id }) => id === 11)['id'], checkedDeviceIssues.map(Number)),
                unableToPairBluetooth: isNumberInList(deviceIssues.find(({ id }) => id === 12)['id'], checkedDeviceIssues.map(Number)),
                deviceFreezes: isNumberInList(deviceIssues.find(({ id }) => id === 13)['id'], checkedDeviceIssues.map(Number)),
                unableToAccessCamera: isNumberInList(deviceIssues.find(({ id }) => id === 14)['id'], checkedDeviceIssues.map(Number)),
                cameraRebootsWhenTakingPicture: isNumberInList(deviceIssues.find(({ id }) => id === 15)['id'], checkedDeviceIssues.map(Number)),
                cameraNotTakingPictures: isNumberInList(deviceIssues.find(({ id }) => id === 16)['id'], checkedDeviceIssues.map(Number)),
                cameraGivingTrouble: isNumberInList(deviceIssues.find(({ id }) => id === 17)['id'], checkedDeviceIssues.map(Number)),
                infiniteBoot: isNumberInList(deviceIssues.find(({ id }) => id === 18)['id'], checkedDeviceIssues.map(Number)),
                digitizerBroken: isNumberInList(deviceIssues.find(({ id }) => id === 19)['id'], checkedDeviceIssues.map(Number)),
                devicePerformanceSlow: isNumberInList(deviceIssues.find(({ id }) => id === 20)['id'], checkedDeviceIssues.map(Number)),
                deviceShutsDown: isNumberInList(deviceIssues.find(({ id }) => id === 21)['id'], checkedDeviceIssues.map(Number)),
                notReadingSimCard: isNumberInList(deviceIssues.find(({ id }) => id === 22)['id'], checkedDeviceIssues.map(Number)),
                warrantAssessment: isNumberInList(deviceIssues.find(({ id }) => id === 23)['id'], checkedDeviceIssues.map(Number)),
                touchIsntWorking: isNumberInList(deviceIssues.find(({ id }) => id === 24)['id'], checkedDeviceIssues.map(Number)),
                screenFlickering: isNumberInList(deviceIssues.find(({ id }) => id === 25)['id'], checkedDeviceIssues.map(Number)),
                deviceShutsDownIntermittently: isNumberInList(deviceIssues.find(({ id }) => id === 26)['id'], checkedDeviceIssues.map(Number)),
                notReadingMemoryCard: isNumberInList(deviceIssues.find(({ id }) => id === 27)['id'], checkedDeviceIssues.map(Number)),
            },
            claimRelatedUserRoles
        ).then(data => {
            console.log(data);
            showSuccessToast(claimType)
        }).catch(error => {
            console.log(error);
            showErrorToast(claimType);
        })
    }

    const createClaim = async () => {
        let base64StringFiles: DocumentData[] = []

        await Promise.all(filesUploaded.map(async (file: File) => {
            const base64String = await convertToBase64(file)
            const documentData: DocumentData = {
                name: file.name,
                file: base64String,
            }
            base64StringFiles.push(documentData);
        }))

        if (claimType.toLowerCase() === 'theft') {
            createTheftClaim(base64StringFiles);
        }
        if (claimType.toLowerCase() === 'physical damage') {
            createPhysicalDamageClaim(base64StringFiles);
        }
        if (claimType.toLowerCase() === 'liquid damage') {
            createLiquidDamageClaim(base64StringFiles)
        }

        // refetch claims if on the policy details page
        if (/\/policies\/\d+/.test(location.pathname)) {
            const part = location.pathname.split('/')
            /* ----- If we are on the policy details route then the number 
            following the '/' after 'policies' would be the imei ----- */
            getClaimsForPolicy(part[2], 1)
        }

        closeModal()
    }

    const showSuccessToast = (type: string) => {
        toast.custom(<AppToaster type={ToastType.SUCCESS} id={ToasterId.CreateClaimSuccess} message={`${capitalizeFirstLetter(type)} claim was created successfully.`}></AppToaster>, { id: ToasterId.CreateClaimSuccess })
    }

    const showErrorToast = (type: string) => {
        toast.custom(<AppToaster type={ToastType.ERROR} id={ToasterId.CreateClaimError} message={`Failed to create ${capitalizeFirstLetter(type)} claim.`}></AppToaster>, { id: ToasterId.CreateClaimError })
    }

    /**
     * Checks and asserts that all required information for a step is set.
     */
    const isAllRequiredDataSet = () => {
        if (physicalDamage) {
            if (onStepOne
                && claimType === 'Physical Damage'
                && policyNum !== ''
                && devicePassword !== ''
                && devicePassword.length > 0
            ) {
                setStepOneComplete(true)
                return true;
            }

            if (onStepTwo && checkedDeviceIssues.length >= 1) {
                setStepTwoComplete(true)
                return true;
            }

            if (onStepThree && selectedDeviceConditions.length >= 1) {
                setStepThreeComplete(true)
                return true;
            }

            if (onStepFour && filesUploaded.length >= 1) {
                setStepFourComplete(true)
                return true;
            }
        }

        if (liquidDamage) {
            if (onStepOne
                && claimType === 'Liquid Damage'
                && policyNum !== ''
                && devicePassword !== ''
                && devicePassword.length > 0
            ) {
                setStepOneComplete(true)
                return true;
            }

            if (onStepTwo && statement.length > 0) {
                setStepTwoComplete(true)
                return true;
            }

            if (onStepThree && filesUploaded.length >= 1) {
                setStepThreeComplete(true)
                return true
            }
        }

        if (theft) {
            if (onStepOne && claimType === 'Theft' && policyNum !== '') {
                setStepOneComplete(true)
                return true;
            }

            if (onStepTwo && checkTheftDetails) {
                setStepTwoComplete(true)
                return true;
            }

            if (onStepThree && statement.length > 0) {
                setStepThreeComplete(true)
                return true
            }

            if (onStepFour && filesUploaded.length >= 1) {
                setStepFourComplete(true)
                return true;
            }
        }
        return false;
    }

    const nextPage = () => {
        if (onStepOne && isAllRequiredDataSet()) {
            setOnStepOne(false)
            setOnStepTwo(true)
        }
        if (onStepTwo && isAllRequiredDataSet()) {
            setOnStepTwo(false)
            setOnStepThree(true)
        }
        if (onStepThree && isAllRequiredDataSet()) {
            if (atEndOfFlow()) {
                createClaim()
            }
            else {
                setOnStepThree(false)
                setOnStepFour(true)
            }
        }
        if (onStepFour && isAllRequiredDataSet()) {
            // save data and create claim logic here
            createClaim()
        }
    };

    const prevPage = () => {
        if (onStepOne) {
            setShowClaimTypeMenu(true)
            setTheft(false)
            setPhysicalDamage(false)
            setLiquidDamage(false)
            setStepOneComplete(false)
            setStepTwoComplete(false)
            setStepThreeComplete(false)
            setStepFourComplete(false)
        }
        if (onStepTwo) {
            setOnStepOne(true)
            setOnStepTwo(false)
        }
        if (onStepThree) {
            setOnStepTwo(true)
            setOnStepThree(false)
        }
        if (onStepFour) {
            setOnStepThree(true)
            setOnStepFour(false)
        }
    };

    const atEndOfFlow = () => {
        switch (claimType.toLowerCase()) {
            case 'theft':
            case 'physical damage':
                return onStepFour;
            case 'liquid damage':
                return onStepThree;
        }
    }

    return (
        <div className='claim-details__container'>
            <QuickActionSidebar
                theft={theft}
                physicalDamage={physicalDamage}
                liquidDamage={liquidDamage}
                onStepOne={onStepOne}
                onStepTwo={onStepTwo}
                onStepThree={onStepThree}
                onStepFour={onStepFour}
                stepOneComplete={stepOneComplete}
                stepTwoComplete={stepTwoComplete}
                stepThreeComplete={stepThreeComplete}
                stepFourComplete={stepFourComplete}
            />

            <div className='claim-details__content-container'>

                {/* ---------- Theft Section ---------- */}
                {
                    onStepOne && theft &&
                    <TheftClaimStepOne
                        setStepOneComplete={setStepOneComplete}
                        claimType={claimType}
                        policyNum={policyNum}
                    />
                }
                {
                    onStepTwo && theft &&
                    <TheftDetails
                        dateLost={dateLost}
                        setDateLost={setDateLost}
                        dateReported={dateReported}
                        setDateReported={setDateReported}
                        policeStation={policeStation}
                        setPoliceStation={setPoliceStation}
                        recordingOfficer={recordingOfficer}
                        setRecordingOfficer={setRecordingOfficer}
                        adviceServiceProvider={adviceServiceProvider}
                        setAdviceServiceProvider={setAdviceServiceProvider}
                    />
                }
                {
                    onStepThree && theft &&
                    <TheftStatement
                        claimType={claimType}
                        statement={statement}
                        setStatement={setStatement}
                    />
                }
                {
                    onStepFour && theft &&
                    <SupportingDocuments
                        claimType={claimType}
                        supportingDocFiles={filesUploaded}
                        setSupportingDocFiles={setFilesUploaded}
                    />
                }

                {/* ---------- Liquid Damage Section ---------- */}
                {
                    onStepOne && liquidDamage &&
                    <PhysicalDamageClaimType
                        policyNumber={policyNumber}
                        claimType={claimType}
                        devicePassword={devicePassword}
                        setDevicePassword={(e: any) => setDevicePassword(e.target.value)}
                    />
                }
                {
                    onStepTwo && liquidDamage &&
                    <TheftStatement
                        claimType={claimType}
                        statement={statement}
                        setStatement={setStatement}
                    />
                }
                {
                    onStepThree && liquidDamage &&
                    <SupportingDocuments
                        claimType={claimType}
                        supportingDocFiles={filesUploaded}
                        setSupportingDocFiles={setFilesUploaded}
                    />
                }

                {/* ---------- Physical Damage Section ---------- */}
                {
                    onStepOne && physicalDamage &&
                    <PhysicalDamageClaimType
                        policyNumber={policyNumber}
                        claimType={claimType}
                        devicePassword={devicePassword}
                        setDevicePassword={(e: any) => setDevicePassword(e.target.value)}
                    />
                }
                {
                    onStepTwo && physicalDamage &&
                    <DeviceIssues
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        deviceIssues={deviceIssues}
                        checkedIssues={checkedDeviceIssues}
                        setCheckedIssues={setCheckedDeviceIssues}
                    />
                }
                {
                    onStepThree && physicalDamage &&
                    <DeviceCondition
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        deviceConditions={deviceConditions}
                        selectedConditions={selectedDeviceConditions}
                        setSelectedConditions={setSelectedDeviceConditions}
                    />
                }
                {
                    onStepFour && physicalDamage &&
                    <SupportingDocuments
                        claimType={claimType}
                        supportingDocFiles={filesUploaded}
                        setSupportingDocFiles={setFilesUploaded}
                    />
                }

                <div className='claim-details__footer'>
                    <Button text="Back" onClick={prevPage} />
                    {
                        atEndOfFlow()
                            ? <Button classes='claim-details__next-btn' text="Save" onClick={nextPage} />
                            : <Button classes='claim-details__next-btn' text="Next" onClick={nextPage} />
                    }
                </div>
            </div>
        </div>
    );
};

export default ClaimTypeDetails;