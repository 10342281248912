import { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react"
import ClaimService from "../../hooks/api/ClaimService";
import { createAction } from "../../utils/reducerUtils";
import { IClaimData, CLAIM_ACTION_TYPES, Meta } from "./claimTypes";
import { useAuthState } from "../Auth/context";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { handleRateLimit } from "../../utils/rateLimitUtil";
import SearchContext from "../Search/SearchContext";

const CLAIM_INITIAL_STATE = {
  data: {
    meta: {} as Meta,
    claimData: []
  },
  isLoading: false,
  sortBy: 'id',
  setSortBy: () => { },
  filterBy: undefined,
  setFilterBy: (filterBy: string) => { },
  itemsPerPage: 10,
  order: 'desc',
  setSortOrderBy: (order: string) => { },
  setItemsPerPage: () => { },
  getAllClaims: (page: number) => { }
}

const claimLocalStorageNames = {
  'claimSort': 'claimSort',
  'claimFilter': 'claimFilter',
  'claimItemsPerPage': 'claimItemsPerPage',
  'claimOrder': 'claimOrder'
}

const ClaimContext = createContext<IClaimData>(CLAIM_INITIAL_STATE);

const claimReducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case CLAIM_ACTION_TYPES.SET_CLAIM_DATA:
      return {
        ...state,
        data: payload
      }
    case CLAIM_ACTION_TYPES.SET_CLAIM_SORT_BY:
      return {
        ...state,
        sortBy: payload
      }
    case CLAIM_ACTION_TYPES.SET_CLAIM_FILTER_BY:
      return {
        ...state,
        filterBy: payload
      }
    case CLAIM_ACTION_TYPES.SET_CLAIM_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: payload
      }
    case CLAIM_ACTION_TYPES.SET_CLAIM_ORDER_TYPE:
      return {
        ...state,
        order: payload
      }

    default:
      throw new Error(`Unhandled type ${type} in userReducer.`);
  }
}

export const ClaimProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(claimReducer, CLAIM_INITIAL_STATE);
  const { searchClaimQuery } = useContext(SearchContext)
  const { token } = useAuthState();
  const [isLoading, setIsLoading] = useState(false)

  const { data, sortBy, filterBy, itemsPerPage, order } = state;

  useEffect(() => {
    // fetchAllUsers(1)
  }, [])

  useEffect(() => {
    const sort = localStorage.getItem(claimLocalStorageNames.claimSort || '');
    const filter = localStorage.getItem(claimLocalStorageNames.claimFilter) || '';
    const order = localStorage.getItem(claimLocalStorageNames.claimOrder || '');
    const ipp = Number.parseInt(localStorage.getItem(claimLocalStorageNames.claimItemsPerPage) || '10');

    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_SORT_BY, sort));
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_FILTER_BY, filter));
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_ITEMS_PER_PAGE, ipp));
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_ORDER_TYPE, order));
  }, []);

  const getAllClaims = useCallback((page: number) => {

    // if (sortBy && itemsPerPage && order) {
    setIsLoading(true)
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

    ClaimService.getAllClaims(page, itemsPerPage, searchClaimQuery, sortBy, filterBy, bearerToken, order).then(response => {
      const claims = response.data;

      setClaimsData(claims);
      // setSortBy(sortBy)
      setIsLoading(false)
    }).catch(err => {
      handleRateLimit(err)
      setIsLoading(false)
    })
    // }
  }, [sortBy, itemsPerPage, order, searchClaimQuery, filterBy, token])

  const setClaimsData = (data: any) => {
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_DATA, data))
  }

  const setSortBy = (sortBy: string) => {
    localStorage.setItem(claimLocalStorageNames.claimSort, sortBy)
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_SORT_BY, sortBy))
  }

  const setFilterBy = (filterBy: string) => {
    localStorage.setItem(claimLocalStorageNames.claimFilter, filterBy)
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_FILTER_BY, filterBy))
  }

  const setItemsPerPage = (id: number) => {
    localStorage.setItem(claimLocalStorageNames.claimItemsPerPage, id.toString())
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_ITEMS_PER_PAGE, id))
  }

  const setSortOrderBy = (order: string) => {
    localStorage.setItem(claimLocalStorageNames.claimOrder, order)
    dispatch(createAction(CLAIM_ACTION_TYPES.SET_CLAIM_ORDER_TYPE, order))
  }

  return (
    <ClaimContext.Provider value={{ data, isLoading, sortBy, setSortBy, filterBy, setFilterBy, itemsPerPage, setItemsPerPage, getAllClaims, setSortOrderBy, order }}>
      {children}
    </ClaimContext.Provider>
  )
}

export default ClaimContext