import React, { useCallback, useState } from "react";
import "./Header.css"

import Searchbar from "../Search/Header/Searchbar";
import Notification from "../Notification/Notification";
import HeaderProfile from "../Profile/HeaderProfile/HeaderProfile";
import { useLocation } from "react-router-dom";

export default function Header() {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const location = useLocation()

    const showSearchBar = useCallback(() => {
        const pathArray = location.pathname.split(/\/(.+)/)

        switch (pathArray[1]) {
            case 'policies':
                return true
            case 'users':
                return true

            case 'claims':
                return true
            default:
                return false
        }
    }, [location])

    const searchBarVisible = showSearchBar()
  
    return (
        <div className="header-container">
            <div className="header-searchbar">
                { searchBarVisible ? <Searchbar/> : null }
                {/* <Searchbar/> */}
            </div>
            <div className="header-left-group">
                {/* <Notification /> */}
                <HeaderProfile/>
            </div>
        </div>
    )
}