import { useState } from 'react';

import { ReactComponent as ColumnIcon } from "../../../../assets/svg/table/column-sort.svg";
import { Status } from '../../../Status/Status';
import Dropdown from "../../../Dropdown/Dropdown";
import { EllipsesIcon } from "../../../../assets/svg/pagination/EllipsesIcon";
import Checkbox from "../../../Checkbox/Checkbox";

import "../../Policies/Table/Table.css"
import "./table.css"

interface InputField {
  headers?: any;
  data?: any;
}

export function Table({ headers, data, }: InputField): JSX.Element {

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isEllipsisDropdownOpen, setIsEllipsisDropdownOpen] = useState(false);

  const addOrToggleCheckedItems = (dataId: string) => {
    let newArr: string[] = [];
    if (checkedItems.includes(dataId)) {
      newArr = checkedItems.filter(item => item !== dataId);
    } else {
      newArr = [...checkedItems, dataId];
    }
    setCheckedItems(newArr);
  }

  const checkAllItemsToggle = () => {
    if (checkedItems.length === data.policyData.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(data.policyData.map((dat: any) => dat.policyNumber));
    }
  }

  const renderEllipsisDropdownOptions = (userId: string,) => {
    return [
      {
        id: 2,
        text: 'Edit',
        selected: false,
        onClick: () => { }
      },
      {
        id: 3,
        text: 'Delete',
        selected: false,
        onClick: () => { }
      }
    ];
  }

  return (
    <table className="table">
      <thead className="table-head">
        <tr>
          {/* <th className="table-header" scope="col" key={'checkbox'}>
            <Checkbox checked={false} onClick={() => checkAllItemsToggle()} />
          </th> */}
          {headers?.map((header: any) => {
            return (
              <th className="table-header" scope="col" key={header}>
                <p className="table-head-title">
                  {header} 
                  {/* {(typeof header === 'string') && <ColumnIcon />} */}
                </p>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.userData.map((datum: any, index: number) => {
          return (
            <tr className={`table-row ${(checkedItems.includes(`${index}`)) ? 'checked' : ''}`} key={index} onClick={() => { }}>
              {/* <td>
                <Checkbox checked={checkedItems.includes(`${index}`)} onClick={() => addOrToggleCheckedItems(`${index}`)} />
              </td> */}
              <td className="table-data">
                <p className="table-data-top">{datum.name}</p>
                {/* <p className="table-data-bottom">{stringToFormattedDate(datum.dateCreated)}</p> */}
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.email}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.role}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.position}</p>
              </td>
              <td>
                <div className="status">
                  <Status title={"Active"}
                    classes={'active'} />

                  {/* <div className="ellipsis-btn">
                    <Dropdown
                      text={<EllipsesIcon />}
                      visible={isEllipsisDropdownOpen}
                      toggleVisibility={() => setIsEllipsisDropdownOpen(!isEllipsisDropdownOpen)}
                      hideArrowIcon={true}
                      styles={userRecordEllipsisDropdownStyle}
                      dropdownAreaStyles={{ minWidth: '190px' }}
                      dropdownTextStyles={{ display: 'flex', margin: 0 }}
                      options={renderEllipsisDropdownOptions('1')} />
                  </div> */}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  )
}

const userRecordEllipsisDropdownStyle = {
  height: '26px',
  padding: '2px 5px',
  backgroundColor: '#E5E7EB',
  border: 'none',
  borderRadius: '8px'
}