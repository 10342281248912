

/**
 * Converts the first letter in a string to uppercase.
 * @param str The string to converted.
 * @param minimizeOtherLetters Whether to minimize tall the letters excepth the first one.
 * Defaults to true.
 */
export const capitalizeFirstLetter = (str: string, minimizeOtherLetters: boolean = true) => {
  if (str) {
    const trailingText = minimizeOtherLetters ? str.slice(1).toLowerCase() : str.slice(1)
    return str.charAt(0).toUpperCase() + trailingText;
  }

  return ""
}

/**
 * Converts the first letter of each word in a string to uppercase.
 * @param str The string to be converted.
 */
export const capitalizeEachFirstLetter = (str: string) => {
  return str.toLowerCase().split(' ').map(word => capitalizeFirstLetter(word)).join(' ');
}

export const convertToBase64 = (file: File) => new Promise<any>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);

});

export const convertToFile = (base64: string, filename: string) => {
  const parts = base64.split(';base64,');
  const fileType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);

  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  const file = new File([new Blob([uInt8Array], { type: fileType })], filename, { type: fileType });
  
  return file
}

export const formatApiDateString = (dateString: string) => {
  const regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}).*/;
  const match = dateString.match(regex);
  
  if (match) {
    return match[1];
  }
  return dateString;
}


