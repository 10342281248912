import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'

import ActionFooter from '../../components/ActionFooter/ActionFooter'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Button from '../../components/Button/Button'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../components/PlaceholderContent/PlaceholderContent'
import CustomerAndDeviceInfo from '../Policy/CustomerAndDeviceInfo/CustomerAndDeviceInfo'
import { actionFooterBtnStyles } from '../Policy/Policy'
import ClaimInfo from './ClaimInfo/ClaimInfo'
import ClaimTheftContent from './ClaimTheftContent/ClaimTheftContent'
import { useAuthState } from '../../contexts'
import { capitalizeFirstLetter, convertToBase64 } from '../../utils/stringUtils'
import { ReactComponent as Check } from '../../assets/svg/check.svg'
import { ReactComponent as Upload } from '../../assets/svg/claims/paperclip.svg';
import { ReactComponent as Document } from '../../assets/svg/claims/files.svg';

import './Claim.css'
import ClaimLiquidContent from './ClaimLiquidContent/ClaimLiquidContent'
import ClaimPhysicalContent from './ClaimPhysicalContent/ClaimPhysicalContent'
import ClaimService, { DocumentData } from '../../services/claims/claimService'
import ContentInfo from '../../components/ContentInfo/ContentInfo'
import { ClaimStatuses } from '../../constants'
import { timeAgo } from '../../utils/dateUtil'
import toast from 'react-hot-toast'
import AppToaster, { ToastType } from '../../components/AppToaster/AppToaster'
import { ToasterId } from '../../enums/ToasterIds'

type Comment = {
  id: number,
  claim_id: string,
  username: string,
  current_status: string,
  content: string,
  created_at: string,
  updated_at: string
}
// TODO - CREATE EXTERNAL COMPONENT FOR COMMENTS
const Comments = ({ data: { claim }, commentsData, addComment }: { data: any, commentsData: Comment[], addComment: (content: string, documents: any) => void }) => {
  const [content, setContent] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState({
    name: "",
    count: 0,
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  }

  const onButtonClick = (e: any) => {
    inputRef.current!.click();
  };

  const generateDocuments = useCallback(async () => {
    let base64StringFiles: DocumentData[] = []
    await Promise.all(uploadedFiles.map(async (file: File) => {
      const base64String = await convertToBase64(file)
      const documentData: DocumentData = {
        name: file.name,
        file: base64String,
      }
      base64StringFiles.push(documentData);
    }))
    
    return base64StringFiles
  }, [uploadedFiles])

  const submitComment = async () => {
    const documents = await generateDocuments()

    addComment(content, documents)
    setContent('')
    setUploadedFiles([])
  }

  const handleDocumentChange = function (e: any) {
    e.preventDefault();
    const newFiles = [...e.target.files];
    let newFiles2 = [...e.target.files];

    handleFiles(newFiles, newFiles2)
  };

  const handleFiles = function (newFiles: any, newFiles2: any) {
    let foundFileOverLimit = false;
    let rejectedFiles: string[] = [];

    newFiles.forEach(async (file: File) => {
      if (file.size > 20971520) {
        rejectedFiles.push(file.name)
        foundFileOverLimit = true;
        newFiles2 = newFiles2.filter((f: any) => f !== file)
      }
    })

    setRejectedFiles({ name: rejectedFiles[0], count: rejectedFiles.length - 1 });
    if (newFiles2.length > 0) {
      setUploadedFiles([...uploadedFiles, ...newFiles2])
    }

    if (foundFileOverLimit) {
      // setOpenUploadFileModal(true)
    }
  }
  
  return (
    <div className='comments-wrapper'>
      <div className='comments-section'>
        <p>Comments</p>
      </div>
      <div className='comments__details'>
        {/* <div style={{display: 'flex'}}>
          <ContentInfo
            classes='p-mr-12'
            label={<p><strong>Status</strong></p>}
            info={[
              <p><strong style={{color: '#6a9234', textTransform: 'capitalize'}}>{ claim.status }</strong></p>
            ]}
            mode='top-down'
            type='status'
          />
        </div> */}

        <div className='comment-status'>
          {
            ClaimStatuses.map((status, index) => {
              const statusFound = ClaimStatuses.find(status => status.toLowerCase().includes(claim.status.toLowerCase())) || status
              const currentStatus = statusFound.toLowerCase() === status.toLowerCase()
              const isNotSelectable = index > ClaimStatuses.indexOf(statusFound.toUpperCase())
              const previousStatusUpdate = index < ClaimStatuses.indexOf(statusFound.toUpperCase())
              const pastUpdates = previousStatusUpdate ? { backgroundColor: 'white' } : {}
              return (
                <span 
                  key={ index } 
                  className={`status-label ${currentStatus ? 'active' : ''}`} 
                  style={{ ...pastUpdates, ...(isNotSelectable ? { cursor: 'not-allowed', color: '#ddd' } : {}) }}>
                  <p>
                    {
                      currentStatus ? <Check /> : null
                    }
                    { status }
                  </p>
                </span>
              )})
          }
        </div>

        <div className='claim-comments'>
          {/* Add Comment Component */}
          <div className="comments">
            {
              commentsData && commentsData.length > 0
              ?
              commentsData.map(comment => (
                <div className='comment-container'>
                  <div className='comment-header'>
                    <h4 className='commenter'>{comment.username}</h4>
                    <h4 className='comment-time'>{timeAgo(comment.created_at)}</h4>
                    |
                    <h4 className='current-status'>{comment.current_status}</h4>
                  </div>
                  <div className="comment-body">  
                    <p>{comment.content}</p>
                  </div>
                </div>
              ))
              :
              null
            }
          </div>
          <div className='add-comment'>
            <textarea 
              value={content}
              onChange={handleChange}
              className="user-comment" 
              maxLength={ 160 }></textarea>
            <hr className='comment-action-seperator'/>
            <div className='comment-actions'>
              <div className='uploaded-docs'>
                {
                  uploadedFiles.map((file: File, index: number) => (
                    <div className='document-item'>
                      <Document />
                      <p className='document-info'>{ file.name } <span>{`(${(file.size / 1048576).toFixed(2)}mb)`}</span></p>
                    </div>
                  ))
                }
              </div>


              <div className='buttons-group'>
                <input ref={ inputRef } id="input-file-upload" type="file" multiple={true} onChange={ handleDocumentChange } accept=".pdf,.doc,.jpeg,.png" />
                <Button
                  text={ <Upload /> }
                  onClick={ onButtonClick }
                  classes='icon-btn'
                />
              
                <Button 
                  text={'Add Comment'}
                  onClick={content.length > 3 ? submitComment : null}
                  classes={content.length > 3 ? 'add-comment-btn' : ''}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Claim = () => {
  const location = useLocation();
  const { token, user } = useAuthState();
  const [claimData, setClaimData] = useState<any>(null);
  const [retrievedComments, setComments] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);

  const fetchData = useCallback(async () => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setLoadingData(true)
   
    let data: any = null

    ClaimService.getClaimDetails(location.state.imei, location.state.claimId, bearerToken)
      .then(response => {
        data = response.data
        setClaimData(data)
        endLoading()
      })
      .catch(error => {
        console.log(error)
        endLoading()
      })

    ClaimService.getComments(location.state.claimId, bearerToken)
      .then(response => {
        data = response.data
        setComments(data.commentsData)
        endLoading()
      })
      .catch(error => {
        console.log(error)
        endLoading()
      })
  }, [])

  const addComment = useCallback(async (content: string, documents: any) => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setLoadingData(true)

    ClaimService.createComment(
      location.state.claimId.toString(), 
      claimData.claim.status, 
      `${user?.firstName} ${user?.lastName}`,
      content,
      documents,
      bearerToken,
    ).then(response => {
      toast.custom(<AppToaster type={ ToastType.SUCCESS } id={ ToasterId.CreateCommentSuccess } message={`Comment succesfully created`}></AppToaster>, { id: ToasterId.CreateCommentSuccess, duration: 5000 })
      fetchData()
      endLoading()
    })
    .catch(error => {
      console.log(error)
      toast.custom(<AppToaster type={ ToastType.ERROR } id={ ToasterId.CreateCommentError } message={`Comment failed to be created`}></AppToaster>, { id: ToasterId.CreateCommentError, duration: 5000 })
      endLoading()
    })
  }, [claimData])

  useEffect(() => {

    fetchData()
  }, [])

  const endLoading = () => {
    setLoadingData(false)
  }

  const renderDetails = (data: any) => {
    switch (data.claim.type.toLowerCase()) {
      case 'theft':
        return <ClaimTheftContent data={claimData} />
      case 'physical damage':
        return <ClaimPhysicalContent data={claimData} />
      case 'liquid damage':
        return <ClaimLiquidContent data={claimData} />
    }
  }

  return <>
    {
      (loadingData && claimData === null) &&
      <LoadingSpinner message='Just a moment. Retrieving the claim details.' />
    }

    {
      (!loadingData && claimData === null) &&
      <>
        <Breadcrumbs lastNodeText={'No Claim'} />
        <PlaceholderContent text='No data for the claim could be found.' />
      </>
    }

    {
      (claimData !== null) && <div>
        <Breadcrumbs lastNodeText={'Claim'} />
        <div className='policy-container'>
          <ClaimInfo data={claimData} fetchClaims={ fetchData } />
          <hr />
          <CustomerAndDeviceInfo data={claimData} />

          { renderDetails(claimData) }

          <Comments data={ claimData } commentsData={ retrievedComments } addComment={ addComment }/>

          <ActionFooter
            leading={[
              // <Button text='Archive' styles={actionFooterBtnStyles} onClick={() => { }} />
            ]}
            trailing={[
              // <Button text='Delete' styles={actionFooterBtnStyles} onClick={() => { }} />
            ]}
          />
        </div>
      </div>
    }
  </>
}

export default Claim