import { useLocation } from 'react-router-dom';
import Button from '../../../components/Button/Button'
import ContentInfo from '../../../components/ContentInfo/ContentInfo'
import { ClaimStatuses, ClaimUpdateRoles } from '../../../constants';
import { stringToFormattedDate } from '../../../utils/dateUtil';
import { capitalizeEachFirstLetter, capitalizeFirstLetter } from '../../../utils/stringUtils';

import './ClaimInfo.css'
import { useState, useCallback, useEffect } from 'react';
import { useAuthState } from '../../../contexts';
import toast from 'react-hot-toast';
import AppToaster, { ToastType } from '../../../components/AppToaster/AppToaster';
import { ToasterId } from '../../../enums/ToasterIds';
import PolicyService from '../../../hooks/api/PolicyService';

interface IClaimInfo {
  data: any;
  fetchClaims: () => void
}

const ClaimInfo = ({ data: { policy, claim }, fetchClaims }: IClaimInfo) => {
  const location = useLocation();
  const { token } = useAuthState();
  const [showEditClaims, setShowEditClaims] = useState(false)
  const [canPerformUpdate, setCanPerformUpdate] = useState(false)
  const [loadingData, setLoadingData] = useState(false);

  const nextStatus = useCallback(() => {
    const currentIndex = ClaimStatuses.indexOf(claim.status.toUpperCase())
    
    if (currentIndex !== -1 && currentIndex < ClaimStatuses.length - 1) {
      return ClaimStatuses[currentIndex + 1].toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }
    return ''
  }, [claim.status])

  useEffect(() => {
    const currentUser = localStorage.getItem("currentUser");
    const nextClaimStatus: string = nextStatus()
    const claimStatusKey = nextClaimStatus.toUpperCase() as keyof typeof ClaimUpdateRoles;
    const approvedUsers = ClaimUpdateRoles[claimStatusKey]
    if (currentUser) {
      try {
        const parsedUser = JSON.parse(currentUser);
        const role: string = parsedUser.user?.role || '';
        setCanPerformUpdate(approvedUsers.some(roleToUpdate => roleToUpdate.trim().toLowerCase() === role.trim().toLowerCase()))
        const insurerRoles = process.env.REACT_APP_INSURER_ROLES || '';

        if (insurerRoles.split(',').includes(role)) {
          setShowEditClaims(false);
        }
      } catch (error) {
        console.error('Error parsing currentUser:', error);
      }
    }
  }, [nextStatus]);

  const updateClaimStatus = useCallback(() => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setLoadingData(true)
    const nextClaimStatus = nextStatus()
    let claimRelatedUserRoles: number[] = [] 
    switch (nextClaimStatus.toLowerCase()) {
      case 'processed': claimRelatedUserRoles.push(1,3,4)
        break
      case 'accounts confirmation': claimRelatedUserRoles.push(1,3)
        break
      default: claimRelatedUserRoles.push(1,3)
    }
    
    PolicyService.updateClaimStatus(location.state.imei, location.state.claimId, bearerToken, nextClaimStatus, claimRelatedUserRoles)
      .then(response => {
        toast.custom(<AppToaster type={ ToastType.SUCCESS } id={ ToasterId.UpdateClaimSuccess } message={`Claim status updated to ${nextStatus()}`}></AppToaster>, { id: ToasterId.UpdateClaimSuccess, duration: 5000 })
        setLoadingData(false)
        fetchClaims()
      })
      .catch(error => {
        toast.custom(<AppToaster type={ ToastType.ERROR } id={ ToasterId.UpdateClaimError } message={error.response.data.message ?? `An error occurred trying to update this claim`}></AppToaster>, { id: ToasterId.UpdateClaimError, duration: 8000 })
        setLoadingData(false)
      })
  }, [nextStatus])

  return (
    <div style={{ display: 'flex' }}>
      <div className='claim-info'>
        <p className='claim-policy-number'>{policy.policyNumber}</p>
        <p className='claim-type'>{capitalizeEachFirstLetter(claim.type)}</p>

        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <ContentInfo
              classes='p-mr-12'
              label={<p><strong>Current Status</strong></p>}
              info={[
                <p><strong style={{color: '#6a9234', textTransform: 'capitalize'}}>{ claim.status }</strong></p>
              ]}
              mode='top-down'
              type='status'
            />
            <ContentInfo
              classes='p-mr-12'
              label={<p><strong>Created On</strong></p>}
              info={[
                <p><strong>{stringToFormattedDate(claim.dateCreated)}</strong></p>
              ]}
              mode='top-down'
            />
            
          </div>
        </div>
      </div>

      <div className='claim-actions' style={{marginBottom: '16px'}}>
        {
          canPerformUpdate
          ?
          <Button 
            styles={{textTransform: 'capitalize'}} 
            text={`${claim.status.toLowerCase() !== 'close' ? `Update to ${nextStatus()}` : claim.status }`} 
            onClick={ claim.status.toLowerCase() !== 'close' ? updateClaimStatus : null } />
          :
          null
        }
        {
          showEditClaims
          ?
          <Button text='Edit Claim' styles={{backgroundColor: '#65A30D', color: 'white'}} onClick={() => { }} />
          :
          null
        }
      </div>
    </div>
  )
}

export default ClaimInfo