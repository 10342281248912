import Button from '../../../Button/Button'
import { ReactComponent as DangerIcon } from '../../../../assets/svg/claims/danger-icon.svg'

import './inactive-policy-modal.css'

interface IInactivePolicyModal {
  setOpenModal: any;
  text: string;
  header: string;
}

const InactivePolicyModal = ({ setOpenModal, text, header = "Cannot create claim"}: IInactivePolicyModal) => {
  return (
    <div className='default-modal-background'>
      <div className='inactive-policy-modal__container'>
        <div className="inactive-policy-modal__content">
          <div>
            <DangerIcon />
          </div>

          <span>{header}</span>
          {/* <p>A claim cannot be created for an in-active policy.</p> */}
          {
            text.split("/").map(para => (
              <p style={{textAlign: 'center', paddingBottom: '2px'}}>{ para }</p>
            ))
          }
        </div>
        <Button classes='sms-btn' text='OK' styles={{ width: '100%' }} onClick={() => setOpenModal(false)} />
      </div>
    </div>
  )
}

export default InactivePolicyModal