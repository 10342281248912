import { Interface } from "readline";

export interface IClaimData {
  data: {
    meta: Meta,
    claimData: any[]
  },
  isLoading: boolean,
  sortBy: string;
  setSortBy: (sortBy: string) => void,
  filterBy: string | undefined,
  setFilterBy: (filterBy: string) => void,
  itemsPerPage: number,
  order: string,
  setSortOrderBy: (order: string) => void,
  setItemsPerPage: (id: number) => void,
  getAllClaims: (page: number) => any,
}

export interface Meta {
  total: number,
  perPage: number,
  currentPage: number,
  lastPage: number,
  firstPageUrl: string,
  lastPageUrl: string,
  nextPageUrl: string,
  prevPageUrl: string,
  path: string,
}

export const CLAIM_ACTION_TYPES = {
  SET_CLAIM_DATA: 'SET_CLAIM_DATA',
  SET_CLAIM_SORT_BY: 'SET_CLAIM_SORT_BY',
  SET_CLAIM_FILTER_BY: 'SSET_CLAIM_FILTER_BY',
  SET_CLAIM_ITEMS_PER_PAGE: 'SET_CLAIM_ITEMS_PER_PAGE',
  SET_CLAIM_ORDER_TYPE:'SET_CLAIM_ORDER_TYPE'
}