const ClaimStatuses: string[] = [
    'SUBMITTED FOR REVIEW',
    'PROCESSING',
    'PROCESSED',
    'ACCOUNTS CONFIRMATION',
    'CLOSE'
]

// TODO - Implement endpoint to handle which roles can perform which claim status updates

const ClaimUpdateRoles = {
    'PROCESSING' : ['INSURER'],
    'PROCESSED' : ['INSURER'],
    'ACCOUNTS CONFIRMATION': ['SMSJ ACCOUNTS'],
    'CLOSE': ['SMSJ OFFICER']
}

export { ClaimStatuses, ClaimUpdateRoles }